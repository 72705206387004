<template>
  <div class="results">
    <v-card color="grey darken-3" class="mb-5" dark>
      <v-card-title>
        <h2>{{ result.name }}</h2>
      </v-card-title>
      <v-card-subtitle>
        {{ result.organization.name }} - {{ result.organization.description
        }}<br />
        Del: {{ formatDate(result.start_at) }} Al:
        {{ formatDate(result.end_at) }}
      </v-card-subtitle>
    </v-card>
    <componentResults :results="result" :electionId="idElection" />
  </div>
</template>

<script>
import elections from "@/services/elections";
import componentResults from "@/components/ballots/BallotResults.vue";

export default {
  name: "electionResults",
  props: ["electionId"],
  components: {
    componentResults
  },
  data: () => ({
    result: {
      name: null,
      organization: {
        name: null
      },
      questions: []
    },
    idElection: null
  }),
  mounted() {
    let self = this;
    elections.publicResults(this.electionId).then(response => {
      self.result = response.data;
      self.idElection = this.electionId;
    });
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      const [d, t] = date.split(" ");

      const [year, month, day] = d.split("-");
      return `${day}/${month}/${year} ${t}`;
    }
  }
};
</script>
