<template>
  <div class="results-election">
    <v-row>
      <v-col cols="11" md="7" class="mx-auto">
        <v-img src="@/assets/tuvoto.png"></v-img>
      </v-col>
    </v-row>
    <publicResults :electionId="this.$route.params.id"></publicResults>
  </div>
</template>

<script>
import publicResults from "@/components/elections/PublicResults.vue";

export default {
  name: "PublicElectionResults",
  components: {
    publicResults
  }
};
</script>
